<template lang="pug">
div(v-if="screen==1")
  .card-body
    .text-center(v-if="visibleItems.length == 0"): small Keine Offenen Buchungen
    .row
      div(v-for="i in visibleItems" :class="screen_id ? 'col-4' : 'col-12'")
        .card.mb-4(v-if="i.wardrobe_call" :class="{blink_me2: true}")
          .card-body.pb-1
            h3 Garderoben Personal angefordert
            .mb-4: strong durch {{i.user_name}} um {{i.local_updated_at}}
            div: button.btn.btn-primary.mb-3(@click="checked(i)" v-if="!screen_id") Erledigt
        .card.mb-4(v-else :class="{blink_me: currentTime - i.time - 60 * 5 < 0}")
          .card-header.pb-1
            h5.float-end Tisch(e): {{i.tabels.join(", ")}}
            h5(v-html="i.ticket.name")
          .card-body
            table.table
              tr 
                th(colspan="2") Änderungen
              tr(v-for="(key, value) in changes(i)")
                th {{fields[value]}}
                td.text-end(v-if="value === 'color'"): span(:style="{color: key}") ███████████
                td.text-end(v-else-if="value === 'gluten' || value === 'nuts' || value === 'fructose'")
                  span(v-if="key === true") JA
                  span(v-if="key === false") NEIN
                
                td.text-end(v-else-if="key === true") {{i.count}} (ehm. 0)
                td.text-end(v-else-if="key === false") 0
                td.text-end(v-else) 
                  | {{key}}
                  span(v-if="i['pre_'+value] || i['pre_'+value] === 0")  (ehm. {{i['pre_'+value]}})
            button.btn.btn-primary.mt-5(@click="checked(i)" v-if="!screen_id") Erledigt
  .card-body.mt-5(v-if="unvisibleItems.length != 0")
    h4(style="cursor: pointer" @click="openUnvisibleItems=!openUnvisibleItems")
      svg(xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24", style="width: 32px; height: 32px; margin-top: -3px;")
        path(fill="currentColor" d="M7,10L12,15L17,10H7Z" v-if="openUnvisibleItems")
        path(fill="currentColor" d="M10,17L15,12L10,7V17Z" v-else)
      | {{unvisibleItems.length}} erledigt
    div(v-if="openUnvisibleItems")
      .row
        div(v-for="i in unvisibleItems" :class="screen_id ? 'col-4' : 'col-12'")
          .card.mb-4(v-if="i.wardrobe_call")
            .card-body.pb-1
              h3 Garderoben Personal angefordert
              .mb-4: strong durch {{i.user_name}} um {{i.local_updated_at}}
          .card.mb-4.pb-1(v-else)
            .card-header
              h5.float-end Tisch(e): {{i.tabels.join(", ")}}
              h5(v-html="i.ticket.name")
            .card-body
              table.table
                tr 
                  th(colspan="2") Änderungen
                tr(v-for="(key, value) in changes(i)")
                  th {{fields[value]}}
                  td.text-end(v-if="value === 'color'"): span(:style="{color: key}") ███████████
                  td.text-end(v-else-if="value === 'gluten' || value === 'nuts' || value === 'fructose'")
                    span(v-if="key === true") JA
                    span(v-if="key === false") NEIN

                  td.text-end(v-else-if="key === true") {{i.count}} (ehm. 0)
                  td.text-end(v-else-if="key === false") 0
                  td.text-end(v-else) 
                    | {{key}}
                    span(v-if="i['pre_'+value] || i['pre_'+value] === 0")  (ehm. {{i['pre_'+value]}})
div(v-else-if="screen==2" style="margin-top: -40px; zoom: 1.2;")
  kitchen-screen(
    v-if="all_events.logo"
    :_data="all_events"
    :refrech="refrech"
  )

</template>

<script>
import KitchenScreen from "./KitchenScreen.vue"
export default {
  components: {
    KitchenScreen
  },
  props: [
    "data",
    "screen_id",
  ],
  channels: {
    GastroChannel: {
      received(data) { 
        this.fetch()
      },
    },
    GastroScreenChannel: {
      received(id) { 
        window.location.href = `/ticketing/roomplan/${id}?ipad=true&list=true&screen_id=${this.screen_id}`
      },
    }
  },
  data () {
    return {
      screen: 1,
      refrech: 1,
      openUnvisibleItems: false,
      fields: {
        vegan_count: "Anzahl Vegan",
        wardrobe_count: "Garderobe Anzahl",
        welcome_drink: "Begrüßungsgetränk (BG)",
        drinks_flat_rate: "Getränkepauschale (GP)",
        kitchen_comment: "Küchen Kommentar",
        gastro_comment: "Gastro Kommentar",
        gastro_count: "Gastro Anzahl",
        gluten: "Glutenallergie",
        nuts: "Nussallergie",
        fructose: "Fructoseintoleranz",
        intolerances: "sonstige Allergien",
        drinks_flat_rate_name: "Getränkepauschale",
        color: "Bändchenfarbe",
        user_name: "geändert durch",
      },
      items: [],
      all_events: [],
      currentTime: 0
    }
  },
  mounted () {
    window.refrech =  this.refrech
    if (this.screen_id){
      let self = this
      let intervalId = window.setInterval(function(){
        self.changeScreen()
      }, 1000 * 50);

      this.updateTime()
      window.setInterval(function(){
        self.updateTime()
      }, 1000 * 20);
    }

    this.items = this.data.gastro_puches
    this.all_events = this.data.all_events

    if (this.screen_id){
      this.$cable.subscribe({
        channel: "TicketingChannel::GastroScreenChannel",
        screen_id: this.screen_id.split(":")[0]
      }, "GastroScreenChannel");
    }

    this.$cable.subscribe({
      channel: "TicketingChannel::GastroChannel",
      token: this.data.channel_token
    }, "GastroChannel");

    document.addEventListener("visibilitychange", () => {
      if (!document.hidden) this.fetch()
    });

  },
  watch: {
  },
  computed: {
    visibleItems(){
      if (!this.items) return []
      return this.items.filter(x=> !x.checked).reverse()
    },
    unvisibleItems(){
      if (!this.items) return []
      return this.items.filter(x=> x.checked).reverse()
    },
  },
  methods: {
    updateTime(){
      const d = new Date();
      let time = d.getTime();
      this.currentTime = time / 1000
    },
    changeScreen(){
      this.refrech = this.refrech+1
      if (this.screen == 1){
        this.screen = 2
      }else if (this.screen == 2){
        this.screen = 1
      }
    },
    fetch(){
      fetch(window.location.href.split("?")[0]+".json?screen_id="+this.screen_id).then(x => x.json()).then((response) => {
        this.refrech = this.refrech+1
        this.items = response.gastro_puches
        this.all_events = response.all_events
      })
    },
    checked(i){
      i.checked = true
      const params = {
        id: i.id,
        checked: i.checked
      }
      fetch("/ticketing/gastro_push.json", {
        method: "POST",
        headers: {
          "X-CSRF-Token": document.getElementsByName("csrf-token")[0].content,
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
        body: JSON.stringify(params)
      })
    },
    changes(i){
      let data = {}
      Object.keys(this.fields).forEach( f => {
        if (i[f] != null){
          data[f] = i[f] 
        }
      })
      return data
    }
  }
}
</script>

<style lang="scss">
</style>