<template lang="pug">
div
  .row.justify-content-end.mb-3(v-if="filters")
    .col-1.form-check.form-switch.float-end(v-for="f in filters" style="width: auto;")
      input.form-check-input(type='checkbox' role='switch' v-model="f.checked" :id="f.value")
      label.form-check-label(:for="f.value") {{f.name}}

  full-calendar(:options='calendarOptions' ref="calendar" v-if="ready")
  .row.justify-content-end.my-5(v-if="filters2")
    .col-1.form-check.form-switch.float-end(v-for="f in filters2" style="width: auto;")
      input.form-check-input(type='checkbox' role='switch' v-model="f.checked" :id="f.value")
      label.form-check-label(:for="f.value") {{f.name}}
  .accordion-item.mb-3.border.border-2.border-secondary.mt-5(v-if="exportrange")
    h2.accordion-header
      button.accordion-button.collapsed(type="button" data-bs-toggle="collapse" data-bs-target="#eventAccordion-export-1" aria-expanded="false" aria-controls="eventAccordion-export"):  strong(style="font-size: 14px;") Excel Export
    .accordion-collapse.collapse(data-bs-parent="#eventAccordion-export" id="eventAccordion-export-1")
      .accordion-body.pt-2.pb-4
        .row.mb-3
          .col-12.col-sm-6
            label.form-label(for="export-from") Von
            input.form-control(type="date" id="export-from" v-model="from")
          .col-12.col-sm-6
            label.form-label(for="export-to") Bis
            input.form-control(type="date" id="export-to" v-model="to")
        .row.m-2.mt-4(v-if="exportFilters")
          .col-1.form-switch.form-check(v-for="f in exportFilters" style="width: auto;")
            input.form-check-input(type='checkbox' role='switch' v-model="f.checked" :id="f.value+'export'")
            label.form-check-label(:for="f.value+'export'") {{f.name}}
        button.btn.btn-primary.mt-4(type="button" @click="download" :disabled="exportLoading") 
          span(class="spinner-border spinner-border-sm" aria-hidden="true" style="margin-top: 3px; margin-right: 10px; width: 14px; height: 14px;" v-if="exportLoading")
          | Excel Tabelle herunterladen
</template>

<script>
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import fileDownload from 'js-file-download';
export default {
  components: {
    FullCalendar
  },
  props: [
    "exportrange",
    "daylink",
    "birthdays",
    "_filters",
    "_filters2"
  ],
  data () {
    return {
      initialView: true,
      filters: null,
      filters2: null,
      exportFilters: null,
      ready: false,
      exportLoading: false,
      from: null,
      to: null,
    }
  },
  mounted () {
    if (this._filters){
      this.filters = JSON.parse(this._filters).map (x => {
        if (localStorage.getItem("filterValue")) x.checked = localStorage.getItem("filterValue").indexOf(x.value) != -1
        return x
      })
      this.exportFilters = JSON.parse(JSON.stringify(this.filters))
    }

    if (this._filters2){
      this.filters2 = JSON.parse(this._filters2).map (x => {
        if (localStorage.getItem("filterValue2")) x.checked = localStorage.getItem("filterValue2").indexOf(x.value) != -1
        return x
      })
    }

    if (this.exportrange){
      this.from = this.exportrange.split(",")[0]
      this.to = this.exportrange.split(",")[1]
    }
    this.ready = true
  },
  watch: {
  },
  computed: {
    filterValue(){
      return this.filters ? "?filter="+this.filters.filter( x => x.checked ).map( x => x.value ).join(",") : ""
    },
    filterValue2(){
      return this.filters2 ? "&filter2="+this.filters2.filter( x => x.checked ).map( x => x.value ).join(",") : ""
    },
    calendarOptions(){
      localStorage.setItem("filterValue2", this.filterValue2)
      localStorage.setItem("filterValue", this.filterValue)
      let self = this
      return {
        initialDate: localStorage.getItem("currentDate"),
        height: "1000px",
        weekNumbers: true,
        plugins: [dayGridPlugin],
        initialView: 'dayGridMonth',
        locale: 'de',
        firstDay: 1,
        buttonText: {
          today: 'Heute',
        },
        eventDisplay: "list-item",
        eventOrder: "parent_event_id,start,-duration,allDay,title",
        events: window.location.href.split("?")[0]+".json"+this.filterValue+this.filterValue2,
        eventClassNames: function(info) {
          if (info.event.extendedProps.parent_event_id) return "parent-event-"+info.event.extendedProps.parent_event_id
        },
        datesSet: function(info) {
          function zfill(num, len) {return (Array(len).join("0") + num).slice(-len);}
          if (self.initialView){
            self.initialView = false
            return false
          }
          localStorage.setItem("currentDate", info.view.currentStart.getFullYear()+"-"+zfill(info.view.currentStart.getMonth()+1, 2)+"-"+zfill(info.view.currentStart.getDate(), 2))
        },
        eventContent: function(info) {
          let css = info.event.extendedProps.css || ""
          let smallHtml = ""
          if (info.event.extendedProps.info){
            smallHtml = "<div class='small'>"+info.event.extendedProps.info+"</div>"
          }
          if (info.event.extendedProps.bgColor){
            css = css+"background: "+info.event.extendedProps.bgColor+";"
          }
          return { html: "<div style='color: #566a7f; border-left: 5px solid "+info.event.extendedProps.secColor+"; padding-left: 5px; overflow: hidden; width: 100%; border-radius: 3px;"+css+"'>"+smallHtml+info.event.title+"</div>" }
        },
        dayCellContent: function(info) {
          function zfill(num, len) {return (Array(len).join("0") + num).slice(-len);}
          let html = self.daylink ? '<a href="'+self.daylink+'?date='+info.date.getFullYear()+"-"+zfill(info.date.getMonth()+1, 2)+"-"+zfill(info.date.getDate(), 2)+'"><strong>+</strong></a>' : ''

          let postHtml = ""
          let date = info.date.toISOString().split("T")[0]
          if (self.todayBirthdays(date)){
            postHtml = '<svg id="tooltip'+date+'" data-bs-toggle="tooltip" data-bs-placement="top" title="'+self.todayBirthdays(date)+'" xmlns="http://www.w3.org/2000/svg" style="width: 14px; float: inline-end; margin-top: 3px; margin-left: 5px;" viewBox="0 0 24 24"><path fill="#ff3e1d" d="M11.5,0.5C12,0.75 13,2.4 13,3.5C13,4.6 12.33,5 11.5,5C10.67,5 10,4.85 10,3.75C10,2.65 11,2 11.5,0.5M18.5,9C21,9 23,11 23,13.5C23,15.06 22.21,16.43 21,17.24V23H12L3,23V17.24C1.79,16.43 1,15.06 1,13.5C1,11 3,9 5.5,9H10V6H13V9H18.5M12,16A2.5,2.5 0 0,0 14.5,13.5H16A2.5,2.5 0 0,0 18.5,16A2.5,2.5 0 0,0 21,13.5A2.5,2.5 0 0,0 18.5,11H5.5A2.5,2.5 0 0,0 3,13.5A2.5,2.5 0 0,0 5.5,16A2.5,2.5 0 0,0 8,13.5H9.5A2.5,2.5 0 0,0 12,16Z" /></svg>'
            setTimeout(() => {
              var exampleEl = document.getElementById('tooltip'+date)
              if (exampleEl) var tooltip = new bootstrap.Tooltip(exampleEl, {boundary: document.body, html: true}) 
            }, 1000);
          }

          return { html: html+postHtml+'<span style="float: inline-end;">'+info.dayNumberText+'</span>' };
        },
        eventMouseEnter: function(info) {
          if (info.event.extendedProps.parent_event_id) document.querySelectorAll(".parent-event-"+info.event.extendedProps.parent_event_id).forEach(x=>x.classList.add('fc-daygrid-dot-event-hover'))
        },
        eventMouseLeave: function(info) {
          document.querySelectorAll(".fc-daygrid-dot-event-hover").forEach(x=>x.classList.remove('fc-daygrid-dot-event-hover'))
        },
      }
    }
  },
  methods: {
    todayBirthdays(date){
      if (this.birthdays) return JSON.parse(this.birthdays)[date.split("-").slice(1).join("-")]
    },
    download(){
      this.exportLoading = true
      const serialize = function(obj) {
        var str = [];
        for (var p in obj)
          if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
          }
        return str.join("&");
      }
      fetch("/staff/events.xls?"+serialize({start: this.from, end: this.to, filter: this.exportFilters.filter( x => x.checked ).map( x => x.value ).join(",")})).then(response => response.blob()).then(data => {
        fileDownload(data, "Eventexport.xls");
        this.exportLoading = false
      })
    }
  }
}
</script>
