<template lang="pug">
.row
  .col-12.my-3(v-for="e in fields" :class="'col-sm-'+e.col + ' ' + (e.class ? e.class(form, parent) : '') ")
    .form-check.form-switch(v-if="e.type == 'boolean'")
      input.form-check-input(:id="e.id+id" type='checkbox' role='switch' v-model="form[e.id]")
      label.form-check-label(:for="e.id+id") {{e.title}}             
    div(v-else-if="e.type == 'nastedForm'")
      h4 {{e.title}}
      .card.mb-3(v-for="(_x, i) in form[e.id]"): .card-body
        active-form(
          :fields='e.fields'
          :errors="{}"
          v-model="form[e.id][i]"
          :id="e.id+'-'+i"
          :name="name"
          :parent="form"
          :parent_id="e.id"
          :domain="domain"
        )
        button.btn.btn-link.text-danger.p-0(type="button" @click="removeNastedItem(e.id, i)")
          svg(viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" style="width: 1.2rem; height: 1.2rem; margin-top: -2px; margin-right: 5px;"): path(d="M17,13H7V11H17M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" fill="currentColor")
          | Eintrag entfernen
      button.btn.btn-link.p-0.mt-5(type="button" @click="addNastedItem(e.id)") 
        svg(viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" style="width: 1.2rem; height: 1.2rem; margin-top: -2px; margin-right: 5px;"): path(d="M17,13H13V17H11V13H7V11H11V7H13V11H17M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" fill="currentColor")
        | Eintrag hinzufügen
      hr
    div(v-else)
      label.form-label(:for="e.id+id") {{e.title}}
      v-select(:options="e.options" :placeholder="e.title" :name="e.id+id" v-model="form[e.id]" v-if="e.type == 'multiple'" multiple)
      textarea.form-control(:name="e.id+id" v-model="form[e.id]" v-else-if="e.type == 'long'" style="height: 150px;")
      select.form-select(:name="e.id+id" v-model="form[e.id]" v-else-if="e.type == 'select'")
        option(v-for="(oTitle, oValue) in e.options" :value="oValue") {{oTitle}}
      active-file(:col="parent_id || e.id" :record="name" v-model="form[e.id]" v-else-if="e.type == 'file'" :domain="domain" :id="e.id+id")
      input.form-control(:name="e.id+id" :placeholder="e.title" :type="e.type" v-model="form[e.id]" v-else)
    small.text-muted(v-if="e.hint") {{e.hint}}
    .text-danger(v-if="errors[e.id]") {{errors[e.id]}}
</template>

<script>
import ActiveFile from "./ActiveFile.vue";
import vSelect from "vue-select";
export default {
  components: {
    vSelect,
    ActiveFile
  },
  props: [
    "modelValue",
    "fields",
    "errors",
    "id",
    "name",
    "domain",
    "parent_id",
    "parent"
  ],
  data () {
    return {
      form: {}
    }
  },
  mounted () {
    this.init()
  },
  watch: {
    modelValue(){
      this.init()
    }
  },
  computed: {
  },
  methods: {
    addNastedItem(id){
      if (!this.form[id]) this.form[id] = []
      this.form[id].push({})
    },
    removeNastedItem(id, index){
      this.form[id] = this.form[id].filter(x => x != this.form[id][index])
    },
    init(){
      this.form = this.modelValue
      this.fields.filter(x => x.type == 'time').map(e => this.form[e.id] =  this.form[e.id] ? this.form[e.id].split("T")[1].split("+")[0] : "")
      this.fields.filter(x => x.type == 'select').map(e => this.form[e.id] = this.form[e.id] || Object.keys(e.options)[0])
      this.fields.filter(x => x.type == 'datetime-local').map(e => this.form[e.id] = this.form[e.id] ? this.form[e.id].split(".")[0] : "")
      this.fields.filter(x => x.type == 'multiple').map(e => this.form[e.id] =  this.form[e.id] ? this.form[e.id].map(x => e.options.find( y => y.id == x || y.id == x.id)) : [])
    }
  }
}
</script>

<style lang="scss">
</style>