<template lang="pug">
.card.m-0
  .card-header
    .row
      .col-sm-9.col-12: h4.mb-0(style="margin-top: 15px;") {{title}}
      .col-sm-3.col-12: select.form-select(v-model="l")
        option(v-for="(title, value) in languages" :value="value") {{title}}
  .card-body(v-if="data")
    ul.nav.nav-pills.mb-3.nav-fill.border.border-primary.rounded(role='tablist')
      li.nav-item(role='presentation' v-for="(title, i) in tabs")
        button.nav-link(:class="p == i ? 'active' : ''" @click="p = i; success = false" type='button' role='tab' data-bs-toggle='tab' data-bs-target='#navs-pills-justified-home' aria-controls='navs-pills-justified-home' aria-selected='true') {{title}}
    .alert.alert-success(v-if="success") Daten wurden gespeichert
    active-form(:fields="fields" :errors="errors" v-model="data" name="domain" :domain="{domain: {...data, domain_name: domainname}}")
    .text-center: button.btn.btn-primary.btn-lg.mt-5(type="button" @click="save" :class="{disabled: loading}")
      span.spinner-border.spinner-border-sm(style="margin-right: 10px;" v-if="loading")
      | Einstellungen speichern
</template>

<script>
export default {
  components: {
  },
  props: [
    "title",
    "id",
    "domainname",
  ],
  data () {
    return {
      pagesSub: [
        "Allgemein",
        "Intro",
        "Kacheln",
        "Gallery",
        "Besonderheiten",
      ],
      pages: [
        "Allgemein",
        "Seiten",
        "Intro",
        "Referenzen",
        "Gallery & Slider",
        "Möglichkeiten",
        "Lageplan",
        "Anfrage",
      ],
      languages: {
        de: "Deutsch",
        en: "Englisch"
      },
      p: 0,
      l: "de",
      data: null,
      errors: {},
      success: false,
      loading: false
    }
  },
  mounted () {
    this.fetch()
  },
  watch: {
  },
  computed: {
    tabs(){
      if (this.data && this.data.sub_page){
        return this.pagesSub
      } else {
        return this.pages
      }
    },
    fieldsSub(){
      return [
        [
          {title: "Unterseite", type: "boolean", id: `sub_page`, col: 12},
          {title: "Name", type: "text", id: `name`, col: 6},
          {title: "Name HTML", type: "text", id: `name_html`, col: 6},
          {title: "Logo", type: "file", id: `logo`, col: 6, hint: "Als SVG, vektorisiert, quadratisch, max. 1 MB"},
          {title: "Fav Logo", type: "file", id: `fav_icon`, col: 6, hint: "Als PNG, 512 x 512 PX, transparenter Hintergrund, max. 1 MB"},
          {title: "Personen Anzahl", type: "text", id: `pax_${this.l}`, col: 6},
          {title: "Title (SEO)", type: "text", id: `title_${this.l}`, col: 6},
          {title: "Beschreibung (SEO)", type: "long", id: `description_${this.l}`, col: 6},
          {title: "Keywords (SEO)", type: "long", id: `keywords_${this.l}`, col: 6},
          {title: "Factsheet", type: "file", id: `factsheet`, col: 12, hint: "Als PDF, so klein wie möglich"},
        ],
        [
          {title: "Überschrift", type: "text", id: `info_title_${this.l}`, col: 9},
          {title: "Button", type: "text", id: `hero_button_${this.l}`, col: 3},
          {title: "Text", type: "long", id: `info_html_${this.l}`, col: 12},
          {title: "Bilder", type: "nastedForm", id: `hero_images`, col: 12, fields: [
            {title: "Bild", type: "file", id: "src", col: 12, hint: "Als WebP, max. 1 MB"},
            {title: "Title", type: "text", id: "title", col: 9},
            {title: "Reihenfolge", type: "number", id: "order", col: 3},
          ]},
        ],
        [
          {title: "Bild", type: "file", id: `cards_lg_image`, col: 9, hint: "Als WebP, 4k Auflösung (3840 x 2160 PX), max. 1 MB"},
          {title: "Button", type: "text", id: `cards_lg_button_${this.l}`, col: 3},
          {title: "Kacheln", type: "nastedForm", id: `cards_lg_items_${this.l}`, col: 12, fields: [
            {title: "Icon", type: "text", id: "icon", col: 12},
            {title: "Title", type: "text", id: "name", col: 12},
            {title: "Beschreibung", type: "long", id: "description", col: 12},
          ]},
        ],
        [
          {title: "Tile", type: "text", id: `gallery_title_${this.l}`, col: 12},
          {title: "Button", type: "text", id: `gallery_button_${this.l}`, col: 3},
          {title: "Bilder", type: "nastedForm", id: `gallery_images`, col: 12, fields: [
            {title: "Bild", type: "file", id: "src", col: 12, hint: "Als WebP, 4k Auflösung (3840 x 2160 PX), max. 1 MB"},
            {title: "Title", type: "text", id: "title", col: 9},
            {title: "Reihenfolge", type: "number", id: "order", col: 3},

          ]},
        ],
        [
          {title: "Tile", type: "text", id: `tabs_title_${this.l}`, col: 9},
          {title: "Bild", type: "file", id: `tabs_image`, col: 12, hint: "Als WebP, 4k Auflösung (3840 x 2160 PX), max. 1 MB"},
          {title: "Tabs", type: "nastedForm", id: `tabs_items_${this.l}`, col: 12, fields: [
            {title: "Icon", type: "text", id: "icon", col: 12},
            {title: "Title", type: "text", id: "title", col: 9},
            {title: "Art", type: "select", id: "type", col: 3, options: {list: "Liste", icons: "Icons"}},
            {title: "Liste", type: "nastedForm", id: "items", col: 12, fields: [
              {title: "Title", type: "text", id: `title`, col: 12},
              {title: "Icon", type: "text", id: `icon`, col: 12, class(_f, form){ return form.type == 'list' ? 'd-none' : ''}},
              {title: "Beschreibung", type: "long", id: `description`, col: 12, class(_f, form){ return form.type == 'list' ? 'd-none' : ''}},
            ]},

          ]},
        ]

      ]
    },
    fields(){
      const fields = [
         [
          {title: "Unterseite", type: "boolean", id: `sub_page`, col: 12},
          {title: "Name", type: "text", id: `name`, col: 6},
          {title: "Title (SEO)", type: "text", id: `title_${this.l}`, col: 6},
          {title: "Beschreibung (SEO)", type: "long", id: `description_${this.l}`, col: 6},
          {title: "Keywords (SEO)", type: "long", id: `keywords_${this.l}`, col: 6},
          {title: "Logo", type: "file", id: `logo`, col: 6, hint: "Als SVG, vektorisiert, quadratisch, max. 1 MB"},
          {title: "Fav Logo", type: "file", id: `fav_icon`, col: 6, hint: "Als PNG, 512 x 512 PX, transparenter Hintergrund, max. 1 MB"},
          {title: "Instagram", type: "text", id: `instagram`, col: 6},
          {title: "Linkedin", type: "text", id: `linkedin`, col: 6},
          {title: "Home", type: "text", id: `home_title_${this.l}`, col: 6},
          {title: "Menü", type: "text", id: `menu_title_${this.l}`, col: 6},
          {title: "Menü Links", type: "nastedForm", id: `links_${this.l}`, col: 12, fields: [
            {title: "Title", type: "text", id: "title", col: 6},
            {title: "URL", type: "text", id: "href", col: 6},
            {title: "Intern verlinkt ?", type: "boolean", id: "intern", col: 12},
          ]},
          {title: "Menü Buttons", type: "nastedForm", id: `links_${this.l}`, col: 12, fields: [
            {title: "Title", type: "text", id: "title", col: 6},
            {title: "URL", type: "text", id: "href", col: 6},
          ]},
          {title: "Dokumente", type: "text", id: `documents_title_${this.l}`, col: 6},
          {title: "Weitere Locations", type: "text", id: `locations_title_${this.l}`, col: 6},
          {title: "Footer Links", type: "nastedForm", id: `page_links_${this.l}`, col: 12, fields: [
            {title: "Title", type: "text", id: "title", col: 6},
            {title: "URL", type: "text", id: "href", col: 6},
          ]},
          {title: "Weitere Locations", type: "nastedForm", id: `locations`, col: 12, fields: [
            {title: "Title", type: "text", id: "title", col: 6},
            {title: "URL", type: "text", id: "href", col: 6},
          ]},
          {title: "Copyright", type: "long", id: `copyright_${this.l}`, col: 12},
          {title: "G-Tag", type: "text", id: `gtag`, col: 12},
        ],
        [
          {title: "Impressum Überschrift", type: "text", id: `impressum_title_${this.l}`, col: 12},
          {title: "Impressum Text", type: "long", id: `impressum_page_${this.l}`, col: 12},
          {title: "Datenschutz Überschrift", type: "text", id: `privacy_policy_title_${this.l}`, col: 12},
          {title: "Datenschutz Text", type: "long", id: `privacy_policy_page_${this.l}`, col: 12},
          {title: "AGB Überschrift", type: "text", id: `agb_title_${this.l}`, col: 12},
          {title: "AGB Text", type: "long", id: `agb_page_${this.l}`, col: 12},
          {title: "404 Überschrift", type: "text", id: `not_found_title_${this.l}`, col: 12},
          {title: "404 Text", type: "long", id: `not_found_text_${this.l}`, col: 12},
          {title: "404 Button", type: "text", id: `not_found_button_${this.l}`, col: 12},
        ],
        [
          {title: "Überschrift", type: "text", id: `intro_h1_${this.l}`, col: 9},
          {title: "Button", type: "text", id: `intro_button_${this.l}`, col: 3},
          {title: "Bild", type: "file", id: "intro_image", col: 12, hint: "Als WebP, 4k Auflösung (3840 x 2160 PX), max. 1 MB"},
          {title: "2. Überschrift", type: "text", id: `intro_h2_${this.l}`, col: 12},
          {title: "Text", type: "long", id: `intro_html_${this.l}`, col: 12},
          {title: "Kacheln", type: "nastedForm", id: `intro_cards_${this.l}`, col: 12, fields: [
            {title: "Icon", type: "text", id: "icon", col: 12},
            {title: "Title", type: "text", id: "title", col: 12},
            {title: "Text", type: "long", id: "text", col: 12},
          ]},
        ],
        [
          {title: "Überschrift", type: "text", id: `references_title_${this.l}`, col: 9},
          {title: "Button", type: "text", id: `references_button_${this.l}`, col: 3},
          {title: "Bild", type: "file", id: "references_image", col: 12, hint: "Als SVG, vektorisiert, max. 1 MB"},
          {title: "Kunden", type: "nastedForm", id: `references_items`, col: 12, fields: [
            {title: "Logo", type: "file", id: "src", col: 12, hint: "Als WebP, 4k Auflösung (3840 x 2160 PX), max. 1 MB"},
            {title: "Name", type: "text", id: "name", col: 9},
            {title: "Größe", type: "text", id: "size", col: 3},
          ]},
        ],
        [
          {title: "Gallery Alle", type: "text", id: `gallery_all_${this.l}`, col: 6},
          {title: "Gallery Button", type: "text", id: `gallery_button${this.l}`, col: 6},
          {title: "Slider Title", type: "text", id: `slider_title_${this.l}`, col: 12},
          {title: "Elemente", type: "nastedForm", id: `slider_items_${this.l}`, col: 12, fields: [
            {title: "Title", type: "text", id: "title", col: 12},
          ]},
        ],
        [
          {title: "Bild", type: "file", id: `cards_image`, col: 6, hint: "Als WebP, 4k Auflösung (3840 x 2160 PX), max. 1 MB"},
          {title: "Title", type: "text", id: `cards_title_${this.l}`, col: 6},
          {title: "Kacheln", type: "nastedForm", id: `cards_items_${this.l}`, col: 12, fields: [
            {title: "Icon", type: "text", id: "icon", col: 6},
            {title: "Title", type: "text", id: "title", col: 6},
            {title: "Elemente", type: "nastedForm", id: `items`, col: 12, fields: [
              {title: "Title", type: "text", id: "title", col: 12},
            ]},
          ]},
        ],
        [
          {title: "PDF", type: "file", id: `map_file`, col: 6, hint: "Als PDF, so klein wie möglich"},
          {title: "Bild", type: "file", id: `map_img`, col: 6, hint: "Als WebP, 960 x 737 PX, max. 1 MB"},
          {title: "Title", type: "text", id: `map_title_${this.l}`, col: 9},
          {title: "Button", type: "text", id: `map_button_${this.l}`, col: 3},
          {title: "Text", type: "long", id: `map_text_${this.l}`, col: 12},
        ],
        [
          {title: "Überschrift", type: "text", id: `contact_title_${this.l}`, col: 12},
          {title: "Telefon", type: "text", id: `contact_phone_title_${this.l}`, col: 4},
          {title: "E-Mail", type: "text", id: `contact_email_title_${this.l}`, col: 4},
          {title: "Adresse", type: "text", id: `contact_address_title_${this.l}`, col: 4},
          {title: "Wunsch-Locations", type: "text", id: `contact_form_locations_title_${this.l}`, col: 6},
          {title: "Alle auswählen", type: "text", id: `contact_form_locations_all_${this.l}`, col: 6},
          {title: "Weiter", type: "text", id: `contact_form_next_${this.l}`, col: 4},
          {title: "Zurück", type: "text", id: `contact_form_prev_${this.l}`, col: 4},
          {title: "Anfrage Absenden", type: "text", id: `contact_form_submit_${this.l}`, col: 4},
          {title: "Pflichtfelder", type: "text", id: `contact_form_required_${this.l}`, col: 12},
          {title: "Veranstaltungsdetails", type: "text", id: `contact_form_event_title_${this.l}`, col: 12},
          {title: "Wann", type: "text", id: `contact_form_event_date_${this.l}`, col: 6},
          {title: "Personen", type: "text", id: `contact_form_event_person_count_${this.l}`, col: 6},
          {title: "Anlass", type: "text", id: `contact_form_event_event_category_${this.l}`, col: 6},
          {title: "Aufbautag bzw. Abbautag gewünscht", type: "text", id: `contact_form_event_need_construction_${this.l}`, col: 6},
          {title: "Anlässe", type: "nastedForm", id: `contact_form_event_event_categories_${this.l}`, col: 12, fields: [
            {value: "Wert", type: "text", id: "value", col: 6},
            {value: "Name", type: "text", id: "name", col: 6},
          ]},
          {title: "Ich benötige", type: "text", id: `contact_form_interests_title_${this.l}`, col: 12},
          {title: "Auswahlmöglichkeiten", type: "nastedForm", id: `contact_form_locations_interests_items_${this.l}`, col: 12, fields: [
            {value: "Icon", type: "text", id: "icon", col: 12},
            {value: "Wert", type: "text", id: "key", col: 12},
            {value: "Title", type: "text", id: "title", col: 12},
          ]},
          {title: "Kontaktdaten", type: "text", id: `contact_form_contact_title_${this.l}`, col: 12},
          {title: "Anrede", type: "text", id: `contact_form_contact_salutation_label_${this.l}`, col: 12},
          {title: "Auswahlmöglichkeiten", type: "nastedForm", id: `contact_form_contact_salutations_${this.l}`, col: 12, fields: [
            {value: "Wert", type: "text", id: "value", col: 6},
            {value: "Title", type: "text", id: "name", col: 6},
          ]},
          {title: "Firma", type: "text", id: `contact_form_contact_company_label_${this.l}`, col: 3},
          {title: "Vorname", type: "text", id: `contact_form_contact_firstname_label_${this.l}`, col: 3},
          {title: "Nachname", type: "text", id: `contact_form_contact_lastname_label_${this.l}`, col: 3},
          {title: "Straße", type: "text", id: `contact_form_contact_street_label_${this.l}`, col: 3},
          {title: "Haus Nr.", type: "text", id: `contact_form_contact_street_number_label_${this.l}`, col: 3},
          {title: "PLZ", type: "text", id: `contact_form_contact_zip_label_${this.l}`, col: 3},
          {title: "Ort", type: "text", id: `contact_form_contact_city_label_${this.l}`, col: 3},
          {title: "Land", type: "text", id: `contact_form_contact_country_label_${this.l}`, col: 3},
          {title: "Telefon", type: "text", id: `contact_form_contact_phone_label_${this.l}`, col: 3},
          {title: "E-Mail", type: "text", id: `contact_form_contact_email_label_${this.l}`, col: 3},
          {title: "Abschluss", type: "text", id: `contact_form_message_title_${this.l}`, col: 6},
          {title: "Nachricht", type: "text", id: `contact_form_message_lable_${this.l}`, col: 6},
          {title: "Disclaimer", type: "long", id: `contact_form_disclaimer_${this.l}`, col: 12},
          {title: "Abgesendet Title", type: "text", id: `contact_form_success_title_${this.l}`, col: 9},
          {title: "Abgesendet Button", type: "text", id: `contact_form_success_button_${this.l}`, col: 3},
          {title: "Abgesendet Text", type: "long", id: `contact_form_success_body_${this.l}`, col: 12},
          {title: "Fehler Title", type: "text", id: `contact_form_error_title_${this.l}`, col: 12},
          {title: "Fehler Text", type: "long", id: `contact_form_error_text_${this.l}`, col: 12},
          {title: "Formular Fehler 1", type: "long", id: `contact_form_form_error1_${this.l}`, col: 4},
          {title: "Formular Fehler 2", type: "long", id: `contact_form_form_error2_${this.l}`, col: 4},
          {title: "Formular Fehler 3", type: "long", id: `contact_form_form_error3_${this.l}`, col: 4},
        ],

      ]
      if (this.data && this.data.sub_page){
        return this.fieldsSub[this.p]
      } else {
        return fields[this.p]
      }
    },
  },
  methods: {
    fetch(){
      fetch("/locations/"+this.id+".json").then(x => x.json()).then((data) => {
        this.data = data
      })
    },
    save(){
      this.loading = true
      this.success = false
      const csrfToken = document.getElementsByName("csrf-token")[0].content
      let options = {
        method: "PATCH",
        headers: {
          "X-CSRF-Token": csrfToken,
          "Content-Type": "application/json",
          "Accept": "application/json",
        }
      }
      let params = {data: {}}
      let form = JSON.parse(JSON.stringify(this.data))
      this.fields.filter(x => x.type == 'multiple').map(e => form[e.id] = form[e.id] ? form[e.id].map(x => x.id) : [])

      params.data.data = form
      options.body = JSON.stringify(params)

      fetch("/locations/"+this.id+".json", options).then(x => x.json()).then((data) => {
        window.scrollTo(0,0)
        this.loading = false
        this.data = data.data
        if (!data.errors || data.errors.length == 0){
          this.errors = {}
          this.success = true
        } else {
          data.errors.map( e => {
            this.errors = {...e}
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
</style>