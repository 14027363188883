<template lang="pug">
div
  .card.mt-3.py-3.px-2(v-if="currentOrder")
    div
      .text-light.fw-medium.float-end Tisch {{currentOrder.table_number}} - {{currentOrder.count}} Gäste
      .text-light.fw-medium {{event.gp_mapping[currentOrder.drinks_flat_rate_name] || "Nach Verbrauch"}} | Part {{currentOrder.part}}
      .mt-2.mb-2 
        .float-start(v-if="currentOrderState!='payment'")
          span.badge.bg-danger.rounded-pill(style='position: relative; top: -10px; right: -32px; padding: 0.3em 0.4em' @click="currentOrderState='cart'") {{getAmount()}}
          svg.text-primary(style='width: 34px;height: 34px;margin-right: 20px;' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' @click="currentOrderState='cart'")
            path(d='M17,18C15.89,18 15,18.89 15,20A2,2 0 0,0 17,22A2,2 0 0,0 19,20C19,18.89 18.1,18 17,18M1,2V4H3L6.6,11.59L5.24,14.04C5.09,14.32 5,14.65 5,15A2,2 0 0,0 7,17H19V15H7.42A0.25,0.25 0 0,1 7.17,14.75C7.17,14.7 7.18,14.66 7.2,14.63L8.1,13H15.55C16.3,13 16.96,12.58 17.3,11.97L20.88,5.5C20.95,5.34 21,5.17 21,5A1,1 0 0,0 20,4H5.21L4.27,2M7,18C5.89,18 5,18.89 5,20A2,2 0 0,0 7,22A2,2 0 0,0 9,20C9,18.89 8.1,18 7,18Z' fill='currentColor')
        .float-end
          svg.text-primary(v-if="currentOrderState!='payment'" style='width: 34px;height: 34px;' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' @click="currentOrderState='payment'")
            path(d='M11 8C11 10.21 9.21 12 7 12C4.79 12 3 10.21 3 8C3 5.79 4.79 4 7 4C9.21 4 11 5.79 11 8M11 14.72V20H0V18C0 15.79 3.13 14 7 14C8.5 14 9.87 14.27 11 14.72M24 20H13V3H24V20M16 11.5C16 10.12 17.12 9 18.5 9C19.88 9 21 10.12 21 11.5C21 12.88 19.88 14 18.5 14C17.12 14 16 12.88 16 11.5M22 7C20.9 7 20 6.11 20 5H17C17 6.11 16.11 7 15 7V16C16.11 16 17 16.9 17 18H20C20 16.9 20.9 16 22 16V7Z' fill='currentColor')
        h3 {{currentOrder.name}}
    .mt-4(v-if="currentOrderState==='cart'")
      li.list-group-item.d-flex.justify-content-between.align-items-center(style="cursor: pointer;" @click="currentOrderState=null")
        h3.m-0
          svg(style='width: 34px;height: 34px;' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg')
            path(d='M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z' fill='currentColor')
          | Zurück
      .mt-5: span.text-light.fw-medium Warenkorb:
      .row
        .col-12(v-for="p in cartObject" style="cursor: pointer;"): .card.mt-3: .card-body: .row
          .col-6
            h4.m-0 {{p.name}}
            .mt-2(v-if="!isCategoryFree(p)"): small.text-danger {{toCurrency(p.price)}}
            .mt-2(v-else): small.text-success Durch {{event.gp_mapping[currentOrder.drinks_flat_rate_name] || "GP"}} abgedeckt
          .col-6: .row
            .col-4.m-0.p-0: .d-grid: button.btn.btn-secondary(type="button" @click="setAmount(p, -1)" :disabled="getAmount(p) == 0") -
            .col-4.m-0.p-0: .d-grid: input.form-control(aria-label='Anzahl' :value="getAmount(p)" @input="x => setAmount(p, 0, x.target.value)" style="text-align: center; font-weight: 800;" type="number" min="1")
            .col-4.m-0.p-0: .d-grid: button.btn.btn-secondary(type="button" @click="setAmount(p, 1)") +
        .d-grid.mt-5: button.btn.btn-danger.btn-lg(type="button" @click="sendOrder") Bestellung schicken


    .mt-4(v-else-if="currentOrderState==='payment'")
      div(v-if="payment")
        span.text-light.fw-medium Betrag:
        input.form-control.mb-3(aria-label='Betrag' v-model="openAmount" style="text-align: center; font-weight: 800; font-size: 24px;" type="number" :min="regularAmount")
        div(v-if="payment === 'card'")
          span.text-light.fw-medium Terminal:
          select.form-control.mb-3(aria-label='Terminal' v-model="terminalId" style="text-align: center; font-weight: 800; font-size: 24px;")
            option(value="1") Terminal 1
            option(value="2") Terminal 2
        .d-grid: button.btn.btn-primary.mt-3.btn-lg(type="button" @click="sendPayment" :disabled="regularAmount > openAmount"): strong BUCHEN
        ul.list-group.mt-4
          li.list-group-item.d-flex.justify-content-between.align-items-center(style="cursor: pointer;" @click="payment=null")
            h3.m-0
              svg(style='width: 34px;height: 34px;' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg')
                path(d='M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z' fill='currentColor')
              | Zurück
      div(v-else)
        span.text-light.fw-medium Zahlungsmethode wählen:
        ul.list-group.mt-2
          li.list-group-item.d-flex.justify-content-between.align-items-center(style="cursor: pointer;" @click="payment='bar'")
            h3.m-0 Bar
            svg(style='width: 34px;height: 34px;' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg')
              path(d='M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z' fill='currentColor')
          li.list-group-item.d-flex.justify-content-between.align-items-center(style="cursor: pointer;" @click="payment='card'")
            h3.m-0 Kartenzahlung
            svg(style='width: 34px;height: 34px;' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg')
              path(d='M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z' fill='currentColor')
          li.list-group-item.d-flex.justify-content-between.align-items-center(style="cursor: pointer;" @click="payment='invoice'")
            h3.m-0 Auf Rechnung
            svg(style='width: 34px;height: 34px;' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg')
              path(d='M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z' fill='currentColor')
          li.list-group-item.d-flex.justify-content-between.align-items-center(style="cursor: pointer;" @click="currentOrderState=null")
            h3.m-0
              svg(style='width: 34px;height: 34px;' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg')
                path(d='M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z' fill='currentColor')
              | Zurück

    .mt-4(v-else)
      div(v-if="currentProductCategory")
        span.text-light.fw-medium {{currentProductCategory}} wählen:
        ul.list-group.mt-2
          li.list-group-item.d-flex.justify-content-between.align-items-center(style="cursor: pointer;" @click="currentProductCategory=null")
            h3.m-0
              svg(style='width: 34px;height: 34px;' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg')
                path(d='M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z' fill='currentColor')
              | Zurück
        .row
          .col-12(v-for="p in productsByCategory(currentProductCategory)" style="cursor: pointer;"): .card.mt-3: .card-body: .row
            .col-6
              h4.m-0 {{p.name}}
              .mt-2(v-if="!isCategoryFree(p)"): small.text-danger {{toCurrency(p.price)}}
              .mt-2(v-else): small.text-success Durch {{event.gp_mapping[currentOrder.drinks_flat_rate_name] || "GP"}} abgedeckt
            .col-6: .row
              .col-4.m-0.p-0: .d-grid: button.btn.btn-secondary(type="button" @click="setAmount(p, -1)" :disabled="getAmount(p) == 0") -
              .col-4.m-0.p-0: .d-grid: input.form-control(aria-label='Anzahl' :value="getAmount(p)" @input="x => setAmount(p, 0, x.target.value)" style="text-align: center; font-weight: 800;" type="number" min="1")
              .col-4.m-0.p-0: .d-grid: button.btn.btn-secondary(type="button" @click="setAmount(p, 1)") +
      div(v-else)
        span.text-light.fw-medium Kategorie wählen:
        ul.list-group.mt-2
          li.list-group-item.d-flex.justify-content-between.align-items-center(style="cursor: pointer;" @click="currentOrder=null")
            h3.m-0
              svg(style='width: 34px;height: 34px;' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg')
                path(d='M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z' fill='currentColor')
              | Zurück
        .row
          .col-6(v-for="c in productCategories" style="cursor: pointer;" @click="currentProductCategory=c"): .card.mt-3(:style="'background-color:'+ getCategoryColor(c)"): .card-body
            h4.m-0(style="color: #fff;") {{c}}
    .mt-4(v-if="currentOrderState == 'payment' || currentOrderState == 'show'")
      span.text-light.fw-medium Gebucht:
      table.table.mt-3
        tr
          th(v-if="payment")
          th Produkt
          th.text-end Preis
        tr(v-for="o in orders" :style="{opacity: o.paid ? 0.5 : 1}")
          th(v-if="payment"): input(class="form-check-input" type="checkbox" v-model="o.checked" :disabled="o.free || o.paid")
          td.nw: div
            | {{o.product.name}}
            .small.text-light.fw-medium.m-0.p-0 {{o.user_name}} - {{o.time}}
          td.text-end
            span(v-if="o.free") GP
            span(v-else) {{toCurrency(o.product.price)}}
        tr
          th(v-if="payment")
          th: .text-end Summe
          th: .text-end {{toCurrency(sumOfOrders)}}
        tr
          th(v-if="payment")
          th: .text-end Bereits gezahlt
          th: .text-end {{toCurrency(sumOfPayments)}}
        tr
          th(v-if="payment")
          th: .text-end Offen
          th: .text-end {{toCurrency(sumOfOrders-sumOfPayments)}}

  .card.mt-3.py-3.px-2(v-else-if="currentTableNumber")
  
    ul.list-group
      li.list-group-item.d-flex.justify-content-between.align-items-center
        .form-check.form-switch.form-switch-xl
          input#partButtons.form-check-input.form-switch-xl(type='checkbox' role='switch' v-model="partButtons")
          label.form-check-label(for='partButtons') Parteien zuweisen
      li.list-group-item.d-flex.justify-content-between.align-items-center
        .form-check.form-switch.form-switch-xl
          input#changeMode.form-check-input.form-switch-xl(type='checkbox' role='switch' v-model="changeMode")
          label.form-check-label(for='changeMode') Plätze tauschen
      li.list-group-item.d-flex.justify-content-between.align-items-center
        .form-check.form-switch.form-switch-xl
          input#veganMode.form-check-input.form-switch-xl(type='checkbox' role='switch' v-model="veganMode")
          label.form-check-label(for='veganMode') Unverträglichkeiten

      li.list-group-item.d-flex.justify-content-between.align-items-center(style="cursor: pointer;" @click="currentTableNumber=null")
        h3.m-0
          svg(style='width: 34px;height: 34px;' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg')
            path(d='M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z' fill='currentColor')
          | Zurück
    .card-body: .row
      .col-12.text-center.border.border-primary(:style="{backgroundColor: table.color}"): strong {{currentTableNumber}}
      .col-12: .row(v-for="index in 2")
        .col-6.text-center.o-person.border.border-primary(
          v-for="(e, i) in tableTickets[index-1]" 
          :style="(e.class == 'text-right' ? 'border-right-width: 0px !important;' : '') + ' background-color: ' + bgColor(e)+ ';' + ' color: ' + (bgColor(e) == '#fff' ? '#000' : '#fff') + ';' "
          :class="(e.id && e.id == changeCol.id) ? 'change-mode' : ''"
          @click="openTicket(e)"
        ): .my-3( v-if="e.name") 
          .o-inner-text {{e.name}}
          strong.o-inner-text
            span(v-if="event.gp_mapping[e.drinks_flat_rate_name]") {{event.gp_mapping[e.drinks_flat_rate_name]}} | 
            u Part {{e.part}} ({{e.i}})
          div: small {{specialComment([e])}}
          .mt-2(v-if="partButtons")
            button.btn.btn-sm.btn-primary.o-button(@click="partUpdate(e, -1)") -
            |   
            button.btn.btn-sm.btn-primary.o-button(@click="partUpdate(e, 1)") +
            |   
            button.btn.btn-sm.btn-primary.o-button(@click="setCurrentPart(e)" v-if="currentPart") 
              svg(style='width: 10px;height: 10px;' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg')
                path(d='M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z' fill='currentColor')
          .mt-2(v-if="partButtons")
            button.btn.btn-sm.btn-primary.o-button(@click="partGroup(e)") Gruppe
            |  
            button.btn.btn-sm.btn-primary.o-button(@click="partIndividual(e)") Einzel
          .mt-2(v-if="veganMode" v-for="(value, key) in veganModes")
            button.btn.btn-sm.btn-primary.o-button(v-if="e['gastro_'+key]" @click="partVegan(key, e, false)") KEIN {{value}}
            button.btn.btn-sm.btn-primary.o-button(v-else @click="partVegan(key, e, true)") {{value}}
          .o-inner-text.mt-4(v-if="veganMode && allSpecialComment(e)"): strong Zusammenfassung:
          .o-inner-text(v-if="veganMode") {{allSpecialComment(e)}}
  .card.mt-3.py-3.px-2(v-else-if="event")
    span.text-light.fw-medium Tisch auswählen:
    .mt-3
      ul.list-group
        li.list-group-item.d-flex.justify-content-between.align-items-center(v-for="tableNumber in  availableTableNumbers" style="cursor: pointer;" @click="currentTableNumber=tableNumber")
          h2.m-0 Tisch {{tableNumber}}
          svg(style='width: 34px;height: 34px;' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg')
            path(d='M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z' fill='currentColor')

</template>

<script>
export default {
  components: {
  },
  props: [
    "_event"
  ],
  data () {
    return {
      veganModes: {
        vegan: "VEGAN",
        gluten: "GLUTENALLAGIE",
        nuts: "NUSSALLAGIE",
        fructose: "FRUCTOSE",
        other: "SONSTIGES"
      },
      terminalId: 1,
      regularAmount: 0,
      openAmount: 0,
      payment: null,
      currentProductCategory: null,
      currentOrderState: null,
      currentTableNumber: null,
      currentOrder: null,
      partButtons: false,
      changeMode: false,
      veganMode: false,
      changeCol: {},
      products: [],
      payments: [],
      orders: null,
      event: {},
      currentPart: null,
      cart: {}
    }
  },
  channels: {
    UpdateChannel: {
      received(data) { 
        fetch(window.location.href.split("?")[0]+".json").then(x => x.json()).then((response) => {
          this.event = response
        })
      },
    },
  },
  mounted () {
    this.event = JSON.parse(this._event)
    fetch("/gastro/products.json").then(x => x.json()).then((response) => {
      this.products = response
    })
    this.$cable.subscribe({
      channel: "TicketingChannel::UpdateChannel",
      token: this.event.channel_token
    }, "UpdateChannel");
  },
  watch: {
    currentOrder(order){
      if (!order) return false
      fetch("/gastro/orderbird/orders/"+this.currentTableNumber+"/"+this.currentOrder.order_id+"/"+this.currentOrder.part+".json").then(x => x.json()).then((response) => {
        this.payments = response.payments
        this.orders = response.orders
      })
    }, 
    payment(payment){
      if (!this.currentOrderState) return false
      if (!this.currentOrder) return false
      this.calcAmount()
      fetch("/gastro/orderbird/orders/"+this.currentTableNumber+"/"+this.currentOrder.order_id+"/"+this.currentOrder.part+".json").then(x => x.json()).then((response) => {
        this.payments = response.payments
        this.orders = response.orders
        this.calcAmount()
      })
    },
  },
  computed: {
    cartObject(){
      let cart = this.cart[this.currentOrder.id]
      return Object.keys(cart).map( x => {
        return this.products.find( p => p.id == x)
      })
    },
    tableTickets() {
      let ticketArray = [];
      let backupArray = [];
      [...Array(Math.ceil((this.table.size||0) / 2))].map((item, i) => {
        ticketArray.push({...this.event.tickets.find( t => t.table_number == this.currentTableNumber && t.col_number == this.colBuilder(i, 1, this.table.size)), class: "text-right", i: this.colBuilder(i, 1, this.table.size), table_number: this.currentTableNumber})
        ticketArray.push({...this.event.tickets.find( t => t.table_number == this.currentTableNumber && t.col_number == this.colBuilder(i, 2, this.table.size)), class: "text-left", i: this.colBuilder(i, 2, this.table.size), table_number: this.currentTableNumber})
      });

      [...Array(Math.ceil((this.table.backup||0) / 2))].map((item, i) => {
        backupArray.push({...this.event.tickets.find( t => t.table_number == this.currentTableNumber && t.col_number == this.colBuilder(i, 1, this.table.backup) + this.table.size), class: "text-right", i: this.colBuilder(i, 1, this.table.backup) + this.table.size, table_number: this.currentTableNumber, backup: true})
        backupArray.push({...this.event.tickets.find( t => t.table_number == this.currentTableNumber && t.col_number == this.colBuilder(i, 2, this.table.backup) + this.table.size), class: "text-left", i: this.colBuilder(i, 2, this.table.backup) + this.table.size, table_number: this.currentTableNumber, backup: true})
      });

      return [ticketArray, backupArray]
    },
    table(){
      const table = this.event.tables[parseInt(this.currentTableNumber)]
      if (table){
        return table
      }else{
        console.log("No Table found: " + this.currentTableNumber)
        return {}
      }
    },
    sumOfOrders(){
      if (!this.orders || this.orders.length == 0) return 0.00
      const sum = this.orders.map( x => {
        if (this.payment ? (!x.checked && !x.paid || x.free) : x.free){
          return 0.0
        } else {
          return x.product.price
        }
      }).reduce((b, a) => b + a, 0);
      if (this.payment) this.calcAmount(sum)
      return sum
    },
    sumOfPayments(){
      return this.payments.map( x => x.regular_amount).reduce((b, a) => b + a, 0);
    },
    productCategories(){
      return Object.keys(Object.groupBy(this.products, (x) => x.category));
    },
    availableTables(){
      let tickets = this.event.tickets.filter(x => this.currentTableNumber === x.table_number).map(x => {
        return JSON.stringify({
          id: x.order_id,
          name: x.name,
          drinks_flat_rate: x.drinks_flat_rate_name,
          table_number: x.table_number,
          count: this.event.tickets.filter(xx => x.order_number == xx.order_number).length
        })
      })
      tickets =  [...new Set(tickets)].map(x => JSON.parse(x))
      return tickets
    },
    availableTableNumbers(){
      if (!this.event.tickets) return []
      return Object.keys(Object.groupBy(this.event.tickets, (x) => x.table_number))
    }
  },
  methods: {
    allSpecialComment(t){
      console.log(this.event.tickets)
      const tickets = this.event.tickets.filter(x => t.name === x.name)
      return tickets.filter(x => x.gluten || x.nuts || x.fructose || (x.intolerances && x.intolerances.length != 0)).map( x => {
        let out = ["Gast"]
        if (x.vegan) out.push("-Vegan")
        out.push(": ")
        if (x.gluten) out.push("Glutenallergie ")
        if (x.nuts) out.push("Nussallergie ")
        if (x.fructose) out.push("Fructoseintoleranz ")
        if (x.intolerances && x.intolerances.length != 0) out.push(x.intolerances+" ")
        return out.join("")
      }).join("<br>")
    },
    openTicket(e){
      if (this.changeMode){
        if (!this.changeCol.id){
          this.changeCol = e
        } else {
          const e_col = e.col_number || e.i
          const ee_col = this.changeCol.col_number || this.changeCol.i
          const _e = this.event.tickets.find(ee => ee.id == e.id)
          const _ee = this.event.tickets.find(ee => ee.id == this.changeCol.id)
          if (_e){
            _e.col_number = ee_col
            this.partUpdate(_e)
          }
          if (_ee){
            _ee.col_number = e_col
            this.partUpdate(_ee)
          } 
          this.changeCol = {}
        }
      } else if (this.veganMode){
        return false
      } else if (this.partButtons){
        return false
      } else if (!e.name){
        return false
      } else{
        this.currentOrder = e
      }
      this.$forceUpdate();
    },
    updateBatch(data){
      this.$forceUpdate();
      const csrfToken = document.getElementsByName("csrf-token")[0].content
      let options = {
        body: JSON.stringify({data: data}),
        method: "POST",
        headers: {
          "X-CSRF-Token": csrfToken,
          "Content-Type": "application/json",
          "Accept": "application/json",
        }
      }
      fetch("/gastro/orderbird/batch", options)
    },
    partGroup(ticket){
      const max = Math.max(...this.event.tickets.filter(x => this.currentTableNumber == x.table_number).map(x => parseInt(x.part||0) )) + 1
      const tickets = this.event.tickets.filter(xx => ticket.order_number == xx.order_number && this.currentTableNumber == xx.table_number)
      let tt = tickets.sort((a, b) => a.col_number - b.col_number).map( (x,i) => {
        x.part = max
        return [x.id, x.part]
      })
      this.updateBatch(tt)
    },
    partIndividual(ticket){
      const max = Math.max(...this.event.tickets.filter(x => this.currentTableNumber == x.table_number).map(x => parseInt(x.part||0) )) + 1
      const tickets = this.event.tickets.filter(xx => ticket.order_number == xx.order_number && this.currentTableNumber == xx.table_number)
      let tt = tickets.sort((a, b) => a.col_number - b.col_number).map( (x,i) => {
        x.part = max + i
        return [x.id, x.part]
      })
      this.updateBatch(tt)
    },
    setAmount(product, value, amount){
      if (!this.cart[this.currentOrder.id]) this.cart[this.currentOrder.id] = {}
      if (amount){
        this.cart[this.currentOrder.id][product.id] = amount
      } else {
        this.cart[this.currentOrder.id][product.id] = (this.cart[this.currentOrder.id][product.id] || 0) + value
      }
      this.$forceUpdate();
    },
    getAmount(product){
      if (product){
        return ( this.cart[this.currentOrder.id] || {} )[product.id] || 0
      } else {
        return Object.values( this.cart[this.currentOrder.id] || {} ).reduce((a, b) => a + b, 0)
      }
    },
    specialComment(tickets){
      return tickets.filter(x => x.gastro_vegan || x.gastro_gluten || x.gastro_gluten || x.gastro_nuts || x.gastro_fructose || x.gastro_other).map( x => {
        let out = []
        if (x.gastro_vegan) out.push("Vegan ")
        if (x.gastro_gluten) out.push("Gluten ")
        if (x.gastro_nuts) out.push("Nuss ")
        if (x.gastro_fructose) out.push("Fructose ")
        if (x.gastro_other) out.push(x.intolerances+" ")
        return out.join("")
      }).join(" | ")
    },
    getCategoryColor(name){
      const p = this.products.find(x => x.category === name)
      if (p) return p.color
    },
    bgColor(e){
      const colorArray = ["#fff", "#FF5733", "#FF6F61", "#FF7F50", "#E67E22", "#F39C12", "#D35400", "#E74C3C", "#C0392B", "#E84118", "#FA8072", "#B23A48", "#9B59B6", "#8E44AD", "#A569BD", "#6C3483", "#2980B9", "#3498DB", "#2874A6", "#1ABC9C", "#16A085", "#27AE60", "#2ECC71", "#239B56", "#F1C40F", "#F39C12", "#D68910", "#D4AC0D", "#A04000", "#AF601A", "#B9770E"];
      return colorArray[e.part % 30]
    },
    setCurrentPart(ticket){
      ticket.part = this.currentPart
      this.$forceUpdate();
      const csrfToken = document.getElementsByName("csrf-token")[0].content
      let options = {
        body: JSON.stringify({id: ticket.id, part: ticket.part, vegan: ticket.vegan}),
        method: "POST",
        headers: {
          "X-CSRF-Token": csrfToken,
          "Content-Type": "application/json",
          "Accept": "application/json",
        }
      }
      fetch("/gastro/orderbird/update", options)
    },
    partVegan(e, ticket, vegan){
      ticket["gastro_"+e] = vegan
      this.partUpdate(ticket)
    },
    partUpdate(ticket, part){
      if (part){
        if (ticket.part + part != -1) ticket.part += part
      }
      this.currentPart = ticket.part
      this.$forceUpdate();
      const csrfToken = document.getElementsByName("csrf-token")[0].content
      let options = {
        body: JSON.stringify({
          id: ticket.id,
          part: ticket.part,
          gastro_vegan: ticket.gastro_vegan,
          gastro_gluten: ticket.gastro_gluten,
          gastro_nuts: ticket.gastro_nuts,
          gastro_fructose: ticket.gastro_fructose,
          gastro_other: ticket.gastro_other,
          col_number: ticket.col_number
        }),
        method: "POST",
        headers: {
          "X-CSRF-Token": csrfToken,
          "Content-Type": "application/json",
          "Accept": "application/json",
        }
      }
      fetch("/gastro/orderbird/update", options)
    },
    calcAmount(sum){
      this.regularAmount = (sum||this.sumOfOrders)-this.sumOfPayments
      this.openAmount = (sum||this.sumOfOrders)-this.sumOfPayments
    },
    colBuilder(i, mode, size){
      if (mode === 1){
        return i+1
      }else{
        return Math.ceil(size / 2)+i+1
      }
    },
    sendPayment(){
      const params = {
        regular_amount: this.regularAmount,
        amount: this.openAmount,
        order_id: this.currentOrder.order_id,
        payment_method: this.payment,
        terminal_id: this.terminalId,
        gastro_order_ids: this.orders.filter(x => x.checked).map(x => x.id),
        table_number: this.currentTableNumber,
        part: this.currentOrder.part
      }

      const csrfToken = document.getElementsByName("csrf-token")[0].content
      let options = {
        body: JSON.stringify(params),
        method: "POST",
        headers: {
          "X-CSRF-Token": csrfToken,
          "Content-Type": "application/json",
          "Accept": "application/json",
        }
      }

      fetch("/gastro/orderbird/payment", options).then(x => x.json()).then((response) => {
        this.orders = response.orders
        this.payments = response.payments
      })

      this.currentOrderState = null
      this.payment = null
    },
    sendOrder(){
      const params = {
        order_id: this.currentOrder.order_id,
        table_number: this.currentTableNumber,
        part: this.currentOrder.part,
        cart: this.cartObject.map(p => {
          return {
            product_id: p.id,
            amount: this.getAmount(p),
            free: this.isCategoryFree(p),
          }
        })
      }
      const csrfToken = document.getElementsByName("csrf-token")[0].content
      let options = {
        body: JSON.stringify(params),
        method: "POST",
        headers: {
          "X-CSRF-Token": csrfToken,
          "Content-Type": "application/json",
          "Accept": "application/json",
        }
      }

      fetch("/gastro/orderbird/book", options).then(x => x.json()).then((response) => {
        this.orders = response.orders
        this.payments = response.payments
      })

      this.cart[this.currentOrder.id] = {}
      this.currentOrderState = null
      this.currentProductCategory = null
      this.currentOrder = null
    },
    isCategoryFree(product){
      let gpName = this.event.gp_mapping[this.currentOrder.drinks_flat_rate_name] || ""
      return product["gp"+gpName.split(" ")[1]]
    },
    productsByCategory(category){
      return this.products.filter(x => x.category == category)
    },
    toCurrency(x){
      return Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(x)
    }
  }
}
</script>

<style lang="scss">
@keyframes flickerAnimation {
  0%   { opacity:1; }
  50%  { opacity:0.3; }
  100% { opacity:1; }
}
@-o-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0.3; }
  100% { opacity:1; }
}
@-moz-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0.3; }
  100% { opacity:1; }
}
@-webkit-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0.3; }
  100% { opacity:1; }
}
.change-mode {
   -webkit-animation: flickerAnimation 1s infinite;
   -moz-animation: flickerAnimation 1s infinite;
   -o-animation: flickerAnimation 1s infinite;
    animation: flickerAnimation 1s infinite;
}

.o-button{
  border: 1px solid #fff !important;
}
.o-person{
  cursor: pointer;
  min-height: 30px;
}
.o-inner-text{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}
.form-switch.form-switch-xl label {
  font-size: calc(1.2875rem + 0.45vw);
  font-weight: 500;
  color: #566a7f;
  margin-left: 20px;
}
.form-switch.form-switch-xl .form-check-input {
  height: 2rem;
  width: calc(3.5rem);
  border-radius: 5rem;
}
</style>